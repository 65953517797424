import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer } from "../footer";
import { Navbar } from "../navbar";
import { useEffect, useState } from "react";
import { Loader } from "../loader"
import axios from "axios";
import './style.css'
import { url } from "../../api";
import { cloudfrontUrl } from "../../api";

function Project({project, thumbnail}){
    return(
        <div className="cart-project">
            <div className="cart-project-image">
                <img alt="Zdjęcie projektu" src={thumbnail}/>
            </div>
            <div className="cart-project-info">
                <div className="cart-project-header">
                    <div className="cart-project-header-info">
                        <div className="cart-project-header-info-header">
                            <h2>{project.name}</h2>
                            <span className="cart-project-area">{Number(project.house_area)}m²</span>
                        </div>
                        <p>{project.small_description}</p>
                    </div>
                </div>
                <div className="cart-project-footer">
                    <span className="cart-project-price">{project.project_price} PLN</span>
                </div>
            </div>
        </div>
    )
}

export function Order(){
    const { id } = useParams()
    const [ project, setProject ] = useState({
        name: "Testowy projekt",
        image: `${cloudfrontUrl}/v2id5Qh.webp`,
        category: "dom piętrowy z poddaszem",
        price: 3500,
        area: 250,
        id: '1',
    })
    const [ thumbnail, setThumbnail ] = useState("")
    const [ order, setOrder ] = useState({})
    const [ loaded, setLoaded ] = useState(false)
    useEffect(() => {
        document.title = `Projekty Gebala - Zamówienie ${id}`;
        axios.get(`${url}/natalia/order/${id}/`)
        .then((res) => {
            setLoaded(true)
            console.log(res.data)
            setOrder(res.data)
            setProject(res.data.project)
            setThumbnail(res.data.project.images.filter((img) => img.isThumbnail === true)[0].image)
            // setThumbnail(res.data.images.filter((img) => img.isThumbnail === true)[0].image)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
        // axios.get(`http://78.47.42.183:8000/natalia/projects/${id}/`)
        // .then((res) => {
        //     setProject(res.data)
        //     console.log(res.data)
        //     setThumbnail(res.data.images.filter((img) => img.isThumbnail === true)[0].image)
        //     document.title = `Projekty Gebala - ${res.data.name}`;
        // })
        // .catch((err) => {
        //     console.error('Error:', err);
        // });
    }, []);
    return(
        <div className="order">
            <div className="container">
                <Navbar></Navbar>
                <img alt="Grafika dom" className="bg-house" src={`${cloudfrontUrl}/bg-house.png`}/>
                {loaded ? 
                    <>
                        <h1 className="cart-title">Sfinalizowałeś zamówienie</h1>
                        <Project project={project} thumbnail={thumbnail}></Project>
                        <h2>Twoje zamówienie zostanie zrealizowane wkrótce. I wysłane na podany adres pocztowy.</h2>
                        <h1 className="order-id">Numer zamówienia - <span className="color-green">{order.order_id}</span></h1>
                        <div className="order-info">
                            <h2>Sposób płatności - {order.payment_method}</h2>
                            <p><span className="color-green">*</span>W przypadku wyboru płatności za pomocą przelewu bankowego, aby dokonać finalizacji płatności, proszę przesłać kwotę zamówienia na podany numer rachunku bankowego: <span className="color-green">{order.bank_account}</span>, <span className="color-green">**</span>podając w tytule imię, nazwisko oraz numer zamówienia.</p>
                        </div>
                    </>
                    :
                    <Loader></Loader>    
                }
            </div>
            <Footer></Footer>
        </div>
    )
}