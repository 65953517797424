import './banner.css'
import { Link } from 'react-router-dom'

export function Banner(){
    return(
        <div className="banner">
            <h1>Masz jakieś pytania? Skontaktuj się z nami już teraz!</h1>
            <p>Wypełnij formularz kontaktowy wraz z opisem tego, czego potrzebujesz, odpowiemy na każde Twoje pytanie!</p>
            <Link reloadDocument to="/kontakt/">
                <button className="btn-outline">Skontaktuj się z nami
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="7.5" stroke="white"/>
                        <path d="M7 5L10 8.42857L7 11" stroke="white"/>
                    </svg>
                </button>
            </Link>
        </div>
    )
}