import { useEffect, useState } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { url } from '../../api'
import { cloudfrontUrl } from "../../api";

export function Footer(){
    const [projects, setProjects ] = useState([])
    useEffect(() => {
        axios.get(`${url}/natalia/project-categories/`)
        .then((res) => {
            setProjects(res.data)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, [])
    return(
        <div className="footer">
            <div className="footer-content container">
                <div className="footer-logo">
                    <img src={`${cloudfrontUrl}/logo_footer.webp`} alt="Logo firmy"/>
                    
                </div>
                <div className="footer-sections">
                    <div className="footer-section">
                        <div className="footer-section-title">Informacje</div>
                        <a href="mailto:projektygebala@gmail.com">projektygebala@gmail.com</a>
                        <a href="tel:+48600238671">+48 600 238 671</a>
                        <a href="tel:+48327541100">+48 32 754 11 00</a>
                        
                        <p>
                            ul. Władysława Sikorskiego 1<br/>
                            32-500 Chrzanów
                        </p>
                        <a href="https://goo.gl/maps/R1CgVrP2ZX6H4hdU7" target="_blank" className="footer-section-more">Zobacz na mapie</a>
                    </div>
                    <div className="footer-section">
                        <div className="footer-section-title">Projekty</div>
                        {projects.map((project) => <Link reloadDocument to={`/katalog/${project.name}`}>{project.label}</Link>)}
                        <Link reloadDocument to="/katalog/" className="footer-section-more">Zobacz więcej</Link>
                    </div>
                    <div className="footer-section">
                        <div className="footer-section-title">Inne</div>
                        <Link reloadDocument to="/kontakt/">Skontaktuj się z nami</Link>
                        <Link reloadDocument to="/uslugi/">Usługi</Link>
                        <Link reloadDocument to="/regulamin/">Regulamin</Link>
                    </div>
                    <div className="footer-section">
                        <div className="footer-section-title">Społeczności</div>
                        <a href="https://www.facebook.com/profile.php?id=61555375711112&notif_id=1715121955987691&notif_t=page_user_activity&ref=notif">Facebook</a>
                        <a href="https://x.com/projektyge65567">Twitter</a>
                        <a href="https://www.instagram.com/projektygebala/">Instagram</a>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                Copyright © 2024. Designed by Norbert Żmuda and developed by <a href="https://github.com/lavoor">Stanisław Synal</a> and <a href="https://github.com/kaiser338">Maciej Król</a>. All rights reserved.
            </div>
        </div>
    )
}