import { Link, useParams, useNavigate } from "react-router-dom";
import { Footer } from "../footer";
import { Navbar } from "../navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import './style.css'
import { Loader } from "../loader"
import { url } from "../../api";
import { cloudfrontUrl } from "../../api";

function Project({project, thumbnail, statustext}){
    return(
        <div className="cart-project">
            <div className="cart-project-image">
                <img alt="Zdjęcie projektu" src={thumbnail}/>
            </div>
            <div className="cart-project-info">
                <div className="cart-project-header">
                    <div className="cart-project-header-info">
                        <div className="cart-project-header-info-header">
                            <h2>{project.name}</h2>
                            <span className="cart-project-area">{project.house_area}m²</span>
                        </div>
                        <p>{project.small_description}</p>
                    </div>
                </div>
                <div className="cart-project-footer">
                    <span className="cart-project-price">{project.project_price} PLN</span>
                    <button type="submit">{statustext}</button>
                </div>
            </div>
        </div>
    )
}

export function Cart(){
    const { id } = useParams()
    let navigate = useNavigate();

    const [ project, setProject ] = useState()
    const [ thumbnail, setThumbnail ] = useState()

    const [ statustext, setStatus ] = useState("Zrealizuj zamówienie")

    const [ payment, setPayment ] = useState("Przelew bankowy")
    const [ message, setMessage ] = useState("")
    const [ name, setName ] = useState("")
    const [ lastname, setLastname ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ phone, setPhone ] = useState("")
    const [ zipcode, setZipcode ] = useState("")
    const [ city, setCity ] = useState("")
    const [ street, setStreet ] = useState("")
    const [ house, setHouse ] = useState("")
    const [ flat, setFlat ] = useState("")

    const [ termsandprivacy, setTermsandprivacy ] = useState(false)
    
    const changePayment = (type, target) => {
        console.log(target)
        let elements = document.querySelectorAll('.cart-form-input-check')
        elements.forEach(element => {
            element.classList.remove('checked')
        })
        target.classList.add('checked')
        setPayment(type)
    }

    const changeTermsandpolicy = () => {
        setTermsandprivacy(!termsandprivacy)
        setStatus("Zrealizuj zamówienie")
    }

    const complete = (e) => {
        console.log(payment)
        let msg = ""
        let xflat = ""
        if(termsandprivacy === false){
            setStatus("Musisz zaakceptować regulamin i politykę prywatności")
            e.preventDefault()
            return
        }
        msg = message
        xflat = flat
        if(message.length === 0){
            msg = "Brak wiadomości dodatkowej"
        }

        setStatus("Składanie zamówienia...")
        axios
        .post(`${url}/natalia/order/`, {
            project_id: id,
            firstname: name,
            lastname: lastname,
            email: email,
            phone_number: phone,
            postcode: zipcode,
            city: city,
            street: street,
            house_number: house,
            flat_number: xflat,
            payment_method: payment,
            text: msg,
        }, {
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(function (response) {
            setStatus("Zrealizowano zamówienie")
            // navigate(`/zamowienie/${response.data.order_id}`, { replace: true })
            window.location.href = `/zamowienie/${response.data.order_id}`;
        })
        .catch(function (error) {
            setStatus(`Nastąpił błąd...`)
            console.log(error)
        });
        e.preventDefault()
    }

    const [ loaded, setLoaded ] = useState(false) 

    useEffect(() => {
        setStatus("Zrealizuj zamówienie")
        document.title = 'Projekty Gebala - Składanie zamówienia';
        axios.get(`${url}/natalia/projects/${id}/`)
        .then((res) => {
            setProject(res.data)
            setThumbnail(res.data.images.filter((img) => img.isThumbnail === true)[0].image)
            setLoaded(true)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, [id]);
    return(
        <div className="cart">
            <div className="container">
                <Navbar></Navbar>
                <img alt="Grafika dom" className="bg-house" src={`${cloudfrontUrl}/bg-house.png`}/>
                {loaded ? 
                <>
                    <h1 className="cart-title">Zamówienie</h1>
                    <form onSubmit={complete}>
                    {project ? <Project statustext={statustext} project={project} thumbnail={thumbnail}></Project> : ""}
                    <div className="cart-form-container">
                        <div className="cart-form-label">
                            <h3>Dane zamawiającego</h3>
                            <input value={name} onChange={event => setName(event.target.value)} required placeholder="Imię*" className="cart-form-input" type="text"/>
                            <input value={lastname} onChange={event => setLastname(event.target.value)} required placeholder="Nazwisko*" className="cart-form-input" type="text"/>
                            <input value={email} onChange={event => setEmail(event.target.value)} required placeholder="Email*" className="cart-form-input" type="email"/>
                            <input value={phone} onChange={event => setPhone(event.target.value)} required placeholder="Telefon*" className="cart-form-input" type="tel"/>
                            <input value={zipcode} onChange={event => setZipcode(event.target.value)} required placeholder="Kod pocztowy*" className="cart-form-input" type="text"/>
                            <input value={city} onChange={event => setCity(event.target.value)} required placeholder="Miejscowość*" className="cart-form-input" type="text"/>
                            <input value={street} onChange={event => setStreet(event.target.value)} required placeholder="Ulica*" className="cart-form-input" type="text"/>
                            <input value={house} onChange={event => setHouse(event.target.value)} required placeholder="Nr domu*" className="cart-form-input" type="number"/>
                            <input value={flat} onChange={event => setFlat(event.target.value)} placeholder="Nr lokalu" className="cart-form-input" type="number"/>
                        </div>
                        <div className="cart-form-label">
                            <h3>Płatność</h3>
                            <div onClick={(event) => changePayment("Przelew bankowy", event.currentTarget)} className="cart-form-input-check checked">
                                <div className="cart-form-input-checkmark"></div>
                                <p className="cart-form-input-title">Przelew bankowy - przelew na podany rachunek bankowy po zrealizowaniu i wygenerowaniu numeru zamówienia. Numer należy podać w tytule przelewu wraz z imieniem i nazwiskiem kupującego.</p>
                            </div>
                            <div onClick={(event) => changePayment("Za pobraniem", event.currentTarget)} className="cart-form-input-check">
                                <div className="cart-form-input-checkmark"></div>
                                <p className="cart-form-input-title payment-2">Za pobraniem</p>
                            </div>
                            <h3>Dodatkowa wiadomość do nas</h3>
                            <textarea value={message} onChange={event => setMessage(event.target.value)} placeholder="Tutaj wpisz wiadomość, która zostanie dołączona do przesłanego przez Ciebie zapytania..."></textarea>
                        </div>
                        <div className="cart-form-label cart-form-label-policy-container">
                            <h3>Oświadczenie</h3>
                            <div className="cart-form-label-policy">
                                <div onClick={changeTermsandpolicy} className={`cart-form-label-policy-checkmark ${termsandprivacy ? "checked" : ""}`}></div>
                                <p><span className="color-green">*</span>Oświadczam, że zapoznałem się i akceptuje <Link className="color-green" reloadDocument target="_blank" to="/regulamin">regulamin</Link> sklepu oraz <Link className="color-green" target="_blank" reloadDocument to="/regulamin">politykę prywatności</Link>.</p>
                            </div>
                        </div>
                    </div>
                    </form>
                </>
                :
                <Loader></Loader>
            }
            </div>
            <Footer></Footer>
        </div>
    )
}