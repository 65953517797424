import { Suspense, lazy } from 'react';
import './style.css'
import { Navbar } from '../navbar/index.js'
import { Footer } from '../footer/index.js'
import { Slider } from './slider.js'
import { About } from './about.js'
import { Tiles } from './tiles.js'
import { Faq } from './faq.js'
import { Banner } from './banner.js'
import { useEffect, useState } from 'react'
import axios from 'axios';
import { url } from '../../api'
import { cloudfrontUrl } from "../../api";

const Projects = lazy(() => import('./projects.js'));


export function Home(){
    const [about, setAbout] = useState("")
    const [faq, setFaq] = useState([])
    const [highlighted, setHighlighted] = useState([])
    const [realizations, setRealizations ] = useState([])
    useEffect(() => {
        document.title = 'Projekty domów 2024 - Możliwość Pełnego Dostosowania Projektu | Projekty Gębala';

        const preloadImages = [
            `${cloudfrontUrl}/bg-house.webp`,
            `${cloudfrontUrl}/bg-house2.webp`
        ];
    
        preloadImages.forEach((image) => {
            const img = new Image();
            img.src = image;

        });

        axios.get(`${url}/natalia/realization-gallery/`)
        .then((res) => {
            let list = res.data
            let len = list.length
            if(len > 3){
                list.slice(-4)
            }
            for(let i = 0; i < len; i++){
                list.push(list[i])
            }
            console.log(list)
            setRealizations(list)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
        axios.get(`${url}/core/about-me/`)
        .then((res) => {
            setAbout(res.data[0])
        })
        .catch((err) => {
            console.error('Error:', err);
        });
        axios.get(`${url}/core/faq/`)
        .then((res) => {
            setFaq(res.data.filter((x) => x.question_type === 'faq'))
        })
        .catch((err) => {
            console.error('Error:', err);
        });
        axios.get(`${url}/natalia/highlighted-projects/`)
        .then((res) => {
            setHighlighted(res.data)
        })
        .catch((err) => {
            console.error('Error:', err);
        })

    }, []);
    return(
        <div className="home">
            <div className='container'>
                <img alt="Grafika dom" className="bg-house" src={`${cloudfrontUrl}/bg-house.webp`} />
                <img alt="Grafika dom 2" className="bg-house2" src={`${cloudfrontUrl}/bg-house2.webp`} />
                <Navbar></Navbar>
                {highlighted ? <Slider slides={highlighted}></Slider> : ""}
                {about ? <About about={about}></About> : ""}
                <Tiles></Tiles>
                <Suspense fallback={<div>Loading...</div>}>
                    {realizations ? <Projects projects={realizations} /> : ""}
                </Suspense>
                {faq ? <Faq faq={faq}></Faq> : ""}
            </div>
            <Banner></Banner>
            <Footer></Footer>
        </div>
    )
}