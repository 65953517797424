import './style.css'
import { Navbar } from '../navbar/index.js'
import { Footer } from '../footer/index.js'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Loader } from '../loader'
import { url } from '../../api'
import { Helmet } from 'react-helmet'
import { cloudfrontUrl } from "../../api";

function OpenedImage({image, closefunc, changeImage}){
    return(
        <div className="gallery-openedimage openedimage">
            <div className="opened-image-arrows">
                <div className="slider-arrow" onClick={() => {changeImage(true)}}>
                    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 2L3 12L13 22" stroke="white" stroke-width="4"/>
                    </svg>
                </div>
                <div className="slider-arrow" onClick={() => {changeImage(false)}}>
                    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 22L12 12L2 2" stroke="white" stroke-width="4"/>
                    </svg>
                </div>
            </div>
            <div className="openedimage-close" onClick={closefunc}>
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.7969 18.9531C23.7109 19.7969 23.7109 21.2734 22.7969 22.1172C22.375 22.5391 21.8125 22.75 21.25 22.75C20.6172 22.75 20.0547 22.5391 19.6328 22.1172L12.25 14.7344L4.79688 22.1172C4.375 22.5391 3.8125 22.75 3.25 22.75C2.61719 22.75 2.05469 22.5391 1.63281 22.1172C0.71875 21.2734 0.71875 19.7969 1.63281 18.9531L9.01562 11.5L1.63281 4.11719C0.71875 3.27344 0.71875 1.79688 1.63281 0.953125C2.47656 0.0390625 3.95312 0.0390625 4.79688 0.953125L12.25 8.33594L19.6328 0.953125C20.4766 0.0390625 21.9531 0.0390625 22.7969 0.953125C23.7109 1.79688 23.7109 3.27344 22.7969 4.11719L15.4141 11.5703L22.7969 18.9531Z" fill="white"/>
                </svg>
            </div>
            <div className="openedimage-img">
                <img alt="podgląd zdjęcia" src={image}/>
            </div>
        </div>
    )
}

function Project({project, category, openImage}){
    return(
        <div className={`gallery-project ${project.is_main ? 'gallery-project-main' : ''}`} onClick={() => openImage(category, project.image)}>
            <div className="gallery-overlay">
                <h2>{project.title}</h2>
                <p>{project.description}</p>
            </div>
            <div className="gallery-img"><img src={project.image} alt="Projekt"/></div>
        </div>
    )
}

export function Gallery(){
    const [ projects, setProjects ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [openedimage, setopenedimage] = useState("")
    const [openedimageid, setopenedimageid] = useState(0)
    const [images, setImages] = useState([])
    useEffect(() => {
        document.title = 'Projekty Gebala - Galeria realizacji';
        axios
        .get(`${url}/natalia/realization-gallery/`)
        .then((res) => {
            setProjects(res.data)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
        axios
        .get(`${url}/natalia/realization-gallery-categories/`)
        .then((res) => {
            setCategories(res.data)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, []);
    const openImage = (category, img) => {
        console.log('test')
        let categoryprojects = projects.filter((project) => project.category === category.id).sort((a, b) => a.order - b.order)
        let newImages = []
        categoryprojects.forEach((project) => {
            newImages.push(project.image)
        })
        setImages(newImages)
        setopenedimage(img)
        let id = newImages.indexOf(img)
        setopenedimageid(id)
    }
    const closeImage = () => {
        setopenedimage("")
    }
    const changeImage = (back) => {
        console.log('test')
        if(back === true){
            let id
            if(openedimageid === 0){
                id = images.length-1
            }
            else{
                id = openedimageid - 1
            }
            setopenedimage(images[id])
            setopenedimageid(id)
        }
        else{
            let id
            if(openedimageid === images.length-1){
                id = 0
            }
            else{
                id = openedimageid + 1
            }
            setopenedimage(images[id])
            setopenedimageid(id)
        }
    }
    return(
        <div className="gallery">
            <Helmet>
                <title>Projekty Gebala - Galeria realizacji</title>
                <meta name="description" content="Zobacz galerię realizacji domów stworzonych przez naszych architektów.Oferujemy projekty domów jednorodzinnych z wizualizacjami, które pomogą Ci wyobrazić sobie ostateczny wygląd Twojego wymarzonego domu. Zainspiruj się naszymi ukończonymi projektami!"/>
            </Helmet>
            {openedimage ? <OpenedImage changeImage={changeImage} closefunc={closeImage} image={openedimage}></OpenedImage> : ""}
            <div className='container'>
                <img alt="Grafika dom" className="bg-house" src={`${cloudfrontUrl}/bg-house.png`}/>
                <Navbar></Navbar>
                    { projects && categories ? 
                        <>
                            {
                                categories.sort((a, b) => a.order - b.order).map((category, i) => (
                                    <div className="gallery-container">
                                        <h1 className={i > 0 ? 'marginedtop' : ''}>{category.label}</h1>
                                        <div className="gallery-projects">
                                        {projects.filter((project) => project.category === category.id).filter((project) => project.is_main === true).slice(0, 1).map((project) => <Project openImage={openImage} category={category} project={project}></Project>)}
                                            {projects.filter((project) => project.category === category.id).filter((project) => project.is_main === false).sort((a, b) => a.order - b.order).map((project) => <Project openImage={openImage} category={category} project={project}></Project>)}
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                        :
                        <Loader></Loader>
                    }
            </div>
            <Footer></Footer>
        </div>
    )
}