import './style.css'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react';
import { cloudfrontUrl } from "../../api";

function NavItem({path, title}){
    const location = useLocation();
    const active = location.pathname.split('/')[1] === path.split('/')[1]
    return(
        <Link reloadDocument to={path} className={`navbar-item ${active ? "active" : ""}`}>{title}</Link>
    )
}

export function Navbar(){
    const [ expanded, setExpanded ] = useState(false)
    return(
        <div className="navbar">
            <Link reloadDocument to="/">
                <div className="navbar-logo">
                    <img src={`${cloudfrontUrl}/logo.webp`} alt="logo firmy"/>
                    {/* <span className="navbar-logo-description">inż. Jacek Gębala - 600 238 671</span> */}
                </div>
            </Link>
            <div className="navbar-resp-button" onClick={() => setExpanded(!expanded)}>
                <svg width="20" height="20" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.375C0 0.910156 0.382812 0.5 0.875 0.5H11.375C11.8398 0.5 12.25 0.910156 12.25 1.375C12.25 1.86719 11.8398 2.25 11.375 2.25H0.875C0.382812 2.25 0 1.86719 0 1.375ZM0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H11.375C11.8398 4.875 12.25 5.28516 12.25 5.75C12.25 6.24219 11.8398 6.625 11.375 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75ZM11.375 11H0.875C0.382812 11 0 10.6172 0 10.125C0 9.66016 0.382812 9.25 0.875 9.25H11.375C11.8398 9.25 12.25 9.66016 12.25 10.125C12.25 10.6172 11.8398 11 11.375 11Z" fill="black"/>
                </svg>
            </div>
            <div className={`navbar-items ${expanded ? 'navbar-expanded' : ""}`}>
                <div className="navbar-resp-button" onClick={() => setExpanded(!expanded)}>
                    <div className="container">
                        <svg className="nav-xmark" width="20" height="20" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.47656 7.64844C9.83203 7.97656 9.83203 8.55078 9.47656 8.87891C9.3125 9.04297 9.09375 9.125 8.875 9.125C8.62891 9.125 8.41016 9.04297 8.24609 8.87891L5.375 6.00781L2.47656 8.87891C2.3125 9.04297 2.09375 9.125 1.875 9.125C1.62891 9.125 1.41016 9.04297 1.24609 8.87891C0.890625 8.55078 0.890625 7.97656 1.24609 7.64844L4.11719 4.75L1.24609 1.87891C0.890625 1.55078 0.890625 0.976562 1.24609 0.648438C1.57422 0.292969 2.14844 0.292969 2.47656 0.648438L5.375 3.51953L8.24609 0.648438C8.57422 0.292969 9.14844 0.292969 9.47656 0.648438C9.83203 0.976562 9.83203 1.55078 9.47656 1.87891L6.60547 4.77734L9.47656 7.64844Z" fill="black"/>
                        </svg>
                    </div>
                </div>
                <NavItem path="/" title="Strona główna"></NavItem>
                <NavItem path="/galeria/" title="Galeria realizacji"></NavItem>
                <NavItem path="/katalog/" title="Katalog projektów"></NavItem>
                <NavItem path="/kacikinwestora/" title="Kącik inwestora"></NavItem>
                <NavItem path="/uslugi/" title="Usługi"></NavItem>
                <Link reloadDocument to="/kontakt/" className="navbar-item navbar-item-button">Skontaktuj się</Link>
            </div>
        </div>
    )
}