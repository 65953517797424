import './style.css'
import { Navbar } from '../navbar/index.js'
import { Footer } from '../footer/index.js'
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { Loader } from '../loader'
import { url } from '../../api'
import { Helmet } from 'react-helmet'
import { cloudfrontUrl } from "../../api";

function Sort({sort, setsortingtype}){
    const [ opened, setOpened ] = useState(false)
    const [ type, setType ] = useState("od najnowszych")
    const sortProjects = (type, typeName) => {
        sort(type)
        setsortingtype(type)
        setOpened(!opened)
        setType(typeName)
    }
    return(
        <div className="catalog-header-sort-input-container">
            <div onClick={() => setOpened(!opened)} className="catalog-header-sort-input">
                <span>{type}</span>
                <span className="catalog-header-sort-input-btn">
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1L6 6L1 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> 
                </span>
            </div>
            <div className={`catalog-header-sort-input-list ${opened ? "visible" : ""}`}>
                <ul>
                    <li onClick={() => sortProjects("newest", "od najnowszych")}>od najnowszych</li>
                    <li onClick={() => sortProjects("oldest", "od najstarszych")}>od najstarszych</li>
                    <li onClick={() => sortProjects("az", "alfabetycznie a-z")}>alfabetycznie a-z</li>
                    <li onClick={() => sortProjects("za", "alfabetycznie z-a")}>alfabetycznie z-a</li>
                    <li onClick={() => sortProjects("pricefromhigh", "cena rosnąco")}>cena rosnąco</li>
                    <li onClick={() => sortProjects("pricefromlow", "cena malejąco")}>cena malejąco</li>
                </ul>
            </div>
        </div>
    )
}

function Filter({filter, turnParameter}){
    const [ checked, setChecked ] = useState(false)
    const clicked = () => {
        setChecked(!checked)
        turnParameter(filter.name)
    }
    return(
        <div className="">
            <li onClick={clicked}><span className={`checkmark ${checked ? "checked" : ""}`}></span><span className="catalog-filter-list-title">{filter.label}</span></li>
        </div>
    )
}

function FilterCategory({category, filters, turnParameter}){
    const [expanded, setExpanded] = useState(false)
    return(
        <div className="catalog-filter">
            <div onClick={() => setExpanded(!expanded)} className="catalog-filter-box">
                <span className="catalog-filter-title">{category.name}</span>
                {!expanded ?             
                    <span className="catalog-filter-btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5V19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    :
                    <span className="catalog-filter-btn">
                        <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1H15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                }
            </div>
            <div className={`catalog-filter-list ${expanded ? "" : "list-notexpanded"}`}>
                <ul>
                    {filters.filter((filter) => filter.category.name === category.name).map((x) => <Filter turnParameter={turnParameter} filter={x}></Filter>)}
                </ul>
            </div>
        </div>
    )
}

function Project({project}){
    let thumbnail = project.images.filter((img) => img.isThumbnail === true)[0].image
    return(
        <div className={`catalog-project ${Number(project.old_price) == 1 ? '' : 'catalog-project-sale'}`}>
            <div className="catalog-project-image">
                <img alt={project.name} src={thumbnail}/>
            </div>
            <div className="catalog-project-header">
                <span className="catalog-project-header-title">{project.name}</span>
                <span className="catalog-project-header-area">{Number(project.house_area)}m²</span>
            </div>
            <p className="catalog-project-description">{project.small_description}</p>
            {Number(project.old_price) == 1 ? <p className="catalog-project-price">{project.project_price} PLN</p>
            :
            <div class="catalog-project-prices">
                <p className="catalog-project-price newprice">{project.project_price} PLN</p>
                <p className="catalog-project-price oldprice">{project.old_price.substring(0, project.old_price.length - 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} PLN</p>
            </div>}
            <Link reloadDocument to={`/projekt/${project.name}`}>
                <button className="catalog-project-button-outline">
                    <span>Zobacz szczegóły</span>
                    <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="7.5" stroke="black"/>
                            <path d="M7 5L10 8.42857L7 11" stroke="black"/>
                        </svg>
                    </span>
                </button>
            </Link>
            {
                project.orderBlocked === false ?
                <Link reloadDocument to={`/koszyk/${project.name}`}>
                    <button className="catalog-project-button">
                        <span>Zamów projekt</span>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 1.33301L2 3.99967V13.333C2 13.6866 2.14048 14.0258 2.39052 14.2758C2.64057 14.5259 2.97971 14.6663 3.33333 14.6663H12.6667C13.0203 14.6663 13.3594 14.5259 13.6095 14.2758C13.8595 14.0258 14 13.6866 14 13.333V3.99967L12 1.33301H4Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2 4H14" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.6668 6.66699C10.6668 7.37424 10.3859 8.05251 9.88578 8.55261C9.38568 9.05271 8.70741 9.33366 8.00016 9.33366C7.29292 9.33366 6.61464 9.05271 6.11454 8.55261C5.61445 8.05251 5.3335 7.37424 5.3335 6.66699" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </button>
                </Link>
                :
                <button className="catalog-project-button catalog-project-button-notbuyable">
                    <span>Brak możliwości zamówienia</span>
                </button>
            }
        </div>
    )
}

export function Catalog(){
    const [filters, setFilters] = useState([])
    const [categories, setCategories] = useState([])
    const [projects, setProjects] = useState([])
    const [visibleProjects, setVisibleProjects] = useState([])
    const [enabledparameters, setEnabledparameters] = useState([])
    const [sortingtype, setsortingtype] = useState("newest")
    const { category } = useParams()
    const [ title, setTitle ] = useState("")
    const sort = (type, projects) => {
        let newProjects = visibleProjects
        if(projects){
            newProjects = projects
        }
        if(type === "newest"){
            newProjects.sort((a, b) => {
                return b.project_order - a.project_order;
            });
        }
        else if(type === "oldest"){
            newProjects.sort((a, b) => {
                return a.project_order - b.project_order;
            });
        }
        else if(type === "az"){
            newProjects.sort((a, b) => {
                let na = a.name.toLowerCase()
                let nb = b.name.toLowerCase()
        
                if (na < nb) {
                    return -1;
                }
                if (na > nb) {
                    return 1;
                }
                return 0;
            });
        }
        else if(type === "za"){
            newProjects.sort((a, b) => {
                let na = a.name.toLowerCase()
                let nb = b.name.toLowerCase()
        
                if (na > nb) {
                    return -1;
                }
                if (na < nb) {
                    return 1;
                }
                return 0;
            });
        }
        else if(type === "pricefromlow"){
            newProjects.sort((a, b) => {
                return Number(b.project_price) - Number(a.project_price);
            });
        }
        else if(type === "pricefromhigh"){
            newProjects.sort((a, b) => {
                return Number(a.project_price) - Number(b.project_price);
            });
        }
        setVisibleProjects([...newProjects])
    }
    const turnParameter = (parameter) => {
        let newParameters = enabledparameters
        if(newParameters.includes(parameter)){
            let id = newParameters.indexOf(parameter)
            if (id > -1) {
                newParameters.splice(id, 1)
                setEnabledparameters([...newParameters])
            }
        }
        else{
            newParameters.push(parameter)
            setEnabledparameters([...newParameters])
        }
        updateProjects(false, newParameters)
    }
    const updateProjects = (freshProjects, parameters) => {
        if(freshProjects){
            setVisibleProjects(freshProjects)
            sort("newest", freshProjects)
        }
        else{
            let filteredProjects = projects
            parameters.forEach((parameter) => {
                filteredProjects = filteredProjects.filter((project) => {
                    return project.parameters.includes(parameter)
                })
            })
            setVisibleProjects([...filteredProjects])
            sort(sortingtype, filteredProjects)
        }
    }
    const [ loaded, setLoaded ] = useState(false)
    useEffect(() => {
        axios.get(`${url}/natalia/projects/`)
        .then((res) => {
            setLoaded(true)
            if(category){
                let newProjects = res.data.filter((item) => item.categories.includes(Number(category)))
                axios.get(`${url}/natalia/project-categories/${category}/`)
                .then((res) => {
                    console.log(res.data.label)
                    setTitle(res.data.label)
                })
                .catch((err) => {
                    console.error('Error:', err);
                });
                setProjects(newProjects)
                updateProjects(newProjects)
            }
            else{
                setTitle("Katalog projektów")
                setProjects(res.data)
                updateProjects(res.data)
            }
        })
        .catch((err) => {
            console.error('Error:', err);
        });
        axios.get(`${url}/natalia/parameter-categories/`)
        .then((res) => {
            setCategories(res.data)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
        axios.get(`${url}/natalia/parameters/`)
        .then((res) => {
            setFilters(res.data)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, [category]);
    return(
        <div className="catalog">
            <Helmet>
                <title>Projekty Gebala - Katalog projektów</title>
                <meta name="description" content="Przeglądaj nasz katalog projektów domów jednorodzinnych. Oferujemy szeroki wybór nowoczesnych projektów z możliwością wprowadzenia wszelakich zmian. Znajdź idealny projekt domu, który spełni Twoje oczekiwania i potrzeby."/>
            </Helmet>
            <div className='container'>
                <img alt="Grafika dom" className="bg-house" src={`${cloudfrontUrl}/bg-house.png`}/>
                <Navbar></Navbar>
                {loaded ? 
                <>                
                    <div className="catalog-wrapper">
                        <div className="catalog-header">
                            <h1>{title}</h1>
                            <div className="catalog-header-sort">
                                <span className="catalog-header-sort-text">Sortuj według</span>
                                <Sort setsortingtype={setsortingtype} sort={sort}></Sort>
                            </div>
                        </div>
                        <div className="catalog-container">
                            <div className="catalog-filters">
                                {categories.map((category) => <FilterCategory turnParameter={turnParameter} category={category} filters={filters}></FilterCategory>)}
                            </div>
                            <div className="catalog-projects">
                                {visibleProjects.map((project) => <Project project={project}></Project>)}
                            </div>
                        </div>
                    </div>
                </>
                :
                <Loader></Loader>
            }
            </div>  
            <Footer></Footer>
        </div>
    )
}