import { Link } from "react-router-dom";
import { Footer } from "../footer";
import { Navbar } from "../navbar";
import { useEffect, useState } from "react";
import './style.css'
import axios from "axios";
import { url } from "../../api";
import { Helmet } from "react-helmet";
import { cloudfrontUrl } from "../../api";


function Cards(){
    return(
        <div className="contact-cards">
            <a href="https://goo.gl/maps/R1CgVrP2ZX6H4hdU7" target="_blank" rel="noreferrer">
                <div className={`contact-card card-location`}>
                    <div className="contact-card-icon">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M40 20C40 32 24 44 24 44C24 44 8 32 8 20C8 15.7565 9.68571 11.6869 12.6863 8.68629C15.6869 5.68571 19.7565 4 24 4C28.2435 4 32.3131 5.68571 35.3137 8.68629C38.3143 11.6869 40 15.7565 40 20Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24 26C27.3137 26 30 23.3137 30 20C30 16.6863 27.3137 14 24 14C20.6863 14 18 16.6863 18 20C18 23.3137 20.6863 26 24 26Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="contact-card-desc">
                        <p>
                            Chrzanów 32-500,<br/>
                            ul. Władysława Sikorskiego 1
                        </p>
                    </div>
                    <span className="card-location-link">Zobacz na mapie</span>
                </div>
            </a>
            <div className={`contact-card card-phone`}>
                <div className="contact-card-icon">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M43.9999 31.8402V37.8402C44.0021 38.3972 43.888 38.9485 43.6649 39.4589C43.4417 39.9692 43.1145 40.4274 42.704 40.8039C42.2936 41.1805 41.809 41.4672 41.2814 41.6456C40.7537 41.8241 40.1946 41.8903 39.6399 41.8402C33.4855 41.1715 27.5739 39.0685 22.3799 35.7002C17.5475 32.6295 13.4505 28.5325 10.3799 23.7002C6.99982 18.4826 4.89635 12.5422 4.23987 6.36019C4.18989 5.80713 4.25562 5.24971 4.43287 4.72344C4.61012 4.19717 4.89501 3.71357 5.2694 3.30344C5.64379 2.8933 6.09948 2.56561 6.60745 2.34124C7.11542 2.11686 7.66455 2.00072 8.21987 2.00019H14.2199C15.1905 1.99064 16.1314 2.33435 16.8674 2.96726C17.6033 3.60017 18.084 4.47909 18.2199 5.44019C18.4731 7.36033 18.9428 9.24565 19.6199 11.0602C19.889 11.776 19.9472 12.554 19.7877 13.302C19.6282 14.0499 19.2576 14.7364 18.7199 15.2802L16.1799 17.8202C19.027 22.8273 23.1728 26.9731 28.1799 29.8202L30.7199 27.2802C31.2636 26.7425 31.9502 26.3719 32.6981 26.2124C33.446 26.0529 34.224 26.1111 34.9399 26.3802C36.7544 27.0573 38.6397 27.5269 40.5599 27.7802C41.5314 27.9173 42.4187 28.4066 43.0529 29.1552C43.6872 29.9038 44.0242 30.8593 43.9999 31.8402Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className="contact-card-desc">
                    <p>
                        +48 600 238 671<br/>
                        +48 32 754 11 00
                    </p>
                </div>
            </div>
            <div className={`contact-card card-mail`}>
                <div className="contact-card-icon">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40 8H8C5.79086 8 4 9.79086 4 12V36C4 38.2091 5.79086 40 8 40H40C42.2091 40 44 38.2091 44 36V12C44 9.79086 42.2091 8 40 8Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M44 14L26.06 25.4C25.4425 25.7869 24.7286 25.992 24 25.992C23.2714 25.992 22.5575 25.7869 21.94 25.4L4 14" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className="contact-card-desc">
                    <p>
                    projektygebala@gmail.com
                    </p>
                </div>
            </div>
        </div>
    )
}

export function Contact(){
    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ message, setMessage ] = useState("")
    const [ type, setType ] = useState("Projekt indywidualny")
    const [ sent, setSent ] = useState("Wyślij zapytanie")
    useEffect(() => {
        document.title = 'Projekty Gebala - Kontakt';
    }, []);
    const declareType = (target) => {
        let elements = document.querySelectorAll('.contact-form-btn')
        elements.forEach(element => {
            element.classList.remove('active')
        })
        target.classList.add('active')
        setType(target.children[1].innerHTML)
    }
    const sendMessage = (e) => {
        setSent("Wysyłanie wiadomości...")
        let msg = ""
        if(message.length > 0){
            msg = message
        }
        else{
            msg = "Brak wiadomości"
        }
        axios
        .post(`${url}/core/contact/`, {
            name: name,
            email: email,
            subject: type,
            message: msg,
        })
        .then(function (response) {
            setSent("Wysłano wiadomość!")
        })
        .catch(function (error) {
            setSent(`Nastąpił błąd...`)
            console.log(error)
        });
        setName("")
        setEmail("")
        setMessage("")
        declareType(document.getElementById('starter'))
        e.preventDefault()
    }
    return(
        <div className="contact">
            <Helmet>
                <title>Projekty Gebala - Kontakt</title>
                <meta name="description" content="Masz pytania dotyczące projektów domów? Skontaktuj się z nami i uzyskaj profesjonalne doradztwo. Nasi specjaliści pomogą Ci na każdym etapie projektowania i budowy wymarzonego domu. Sprawdź nasze usługi i skonsultuj swoje pomysły!"/>
            </Helmet>
            <div className="container">
                <Navbar></Navbar>
                <img alt="Grafika dom" className="bg-house" src={`${cloudfrontUrl}/bg-house.png`}/>
                <h1>Skontaktuj się z nami</h1>
                <Cards></Cards>
                <div className="contact-form">
                    <h2>O co chciałbyś nas zapytać?</h2>
                    <form onSubmit={sendMessage}>
                        <div className="contact-form-inputs">
                            <div className="contact-form-inputs-row">
                                <div className="contact-form-input">
                                    <p>Imię i nazwisko</p>
                                    <input required value={name} onChange={event => setName(event.target.value)} placeholder="Maciek Kowalski" type="text"/>
                                </div>
                                <div className="contact-form-input">
                                    <p>Adres e-mail</p>
                                    <input required value={email} onChange={event => setEmail(event.target.value)} placeholder="email@email.pl" type="email"/>
                                </div>
                            </div>
                            <div className="contact-form-inputs-row">
                                <p className="contact-form-inputs-text">Z czym potrzebujesz pomocy? Wszystkie usługi, które świadczymy są opisane na <Link reloadDocument to="/uslugi/">tej stronie</Link>.</p>
                            </div>
                            <div className="contact-form-inputs-row">
                                <div id="starter" onClick={(event) => declareType(event.currentTarget)} className="contact-form-btn active">
                                    <span className="contact-form-input-checkmark"></span>
                                    <span className="contact-form-input-title">Projekt indywidualny</span>
                                </div>
                                <div onClick={(event) => declareType(event.currentTarget)} className="contact-form-btn">
                                    <span className="contact-form-input-checkmark"></span>
                                    <span className="contact-form-input-title">Realizacja projektu z innego biura</span>
                                </div>
                            </div>
                            <div className="contact-form-inputs-row">
                                <div onClick={(event) => declareType(event.currentTarget)} className="contact-form-btn">
                                    <span className="contact-form-input-checkmark"></span>
                                    <span className="contact-form-input-title">Indywidualne konsultacje</span>
                                </div>
                                <div onClick={(event) => declareType(event.currentTarget)} className="contact-form-btn">
                                    <span className="contact-form-input-checkmark"></span>
                                    <span className="contact-form-input-title">Płatności</span>
                                </div>
                            </div>
                            <div className="contact-form-inputs-row">
                                <div onClick={(event) => declareType(event.currentTarget)} className="contact-form-btn">
                                    <span className="contact-form-input-checkmark"></span>
                                    <span className="contact-form-input-title">Zaświadczenia</span>
                                </div>
                                <div onClick={(event) => declareType(event.currentTarget)} className="contact-form-btn">
                                    <span className="contact-form-input-checkmark"></span>
                                    <span className="contact-form-input-title">Inne zapytanie</span>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form-message">
                            <div className="contact-form-input">
                                <p>Dodatkowa wiadomość do nas</p>
                                <textarea onChange={event => setMessage(event.target.value)} value={message} placeholder="Tutaj wpisz wiadomość, która zostanie dołączona do przesłanego przez Ciebie zapytania..."></textarea>
                            </div>
                            <button type="submit">{sent}</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}