import './style.css'
import { Navbar } from '../navbar/index.js'
import { Footer } from '../footer/index.js'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Loader } from '../loader'
import { url } from '../../api'
import React from 'react'
import { cloudfrontUrl } from "../../api";

function OpenedPlan({image, closefunc}){
    return(
        <div className="openedimage">
            <div className="openedimage-close" onClick={closefunc}>
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.7969 18.9531C23.7109 19.7969 23.7109 21.2734 22.7969 22.1172C22.375 22.5391 21.8125 22.75 21.25 22.75C20.6172 22.75 20.0547 22.5391 19.6328 22.1172L12.25 14.7344L4.79688 22.1172C4.375 22.5391 3.8125 22.75 3.25 22.75C2.61719 22.75 2.05469 22.5391 1.63281 22.1172C0.71875 21.2734 0.71875 19.7969 1.63281 18.9531L9.01562 11.5L1.63281 4.11719C0.71875 3.27344 0.71875 1.79688 1.63281 0.953125C2.47656 0.0390625 3.95312 0.0390625 4.79688 0.953125L12.25 8.33594L19.6328 0.953125C20.4766 0.0390625 21.9531 0.0390625 22.7969 0.953125C23.7109 1.79688 23.7109 3.27344 22.7969 4.11719L15.4141 11.5703L22.7969 18.9531Z" fill="white"/>
                </svg>
            </div>
            <div className="openedimage-img">
                <img alt="podgląd zdjęcia" src={image}/>
            </div>
        </div>
    )
}

function OpenedImage({image, closefunc, changeImage}){
    return(
        <div className="openedimage">
            <div className="opened-image-arrows">
                <div className="slider-arrow" onClick={() => {changeImage(true)}}>
                    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 2L3 12L13 22" stroke="white" stroke-width="4"/>
                    </svg>
                </div>
                <div className="slider-arrow" onClick={() => {changeImage(false)}}>
                    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 22L12 12L2 2" stroke="white" stroke-width="4"/>
                    </svg>
                </div>
            </div>
            <div className="openedimage-close" onClick={closefunc}>
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.7969 18.9531C23.7109 19.7969 23.7109 21.2734 22.7969 22.1172C22.375 22.5391 21.8125 22.75 21.25 22.75C20.6172 22.75 20.0547 22.5391 19.6328 22.1172L12.25 14.7344L4.79688 22.1172C4.375 22.5391 3.8125 22.75 3.25 22.75C2.61719 22.75 2.05469 22.5391 1.63281 22.1172C0.71875 21.2734 0.71875 19.7969 1.63281 18.9531L9.01562 11.5L1.63281 4.11719C0.71875 3.27344 0.71875 1.79688 1.63281 0.953125C2.47656 0.0390625 3.95312 0.0390625 4.79688 0.953125L12.25 8.33594L19.6328 0.953125C20.4766 0.0390625 21.9531 0.0390625 22.7969 0.953125C23.7109 1.79688 23.7109 3.27344 22.7969 4.11719L15.4141 11.5703L22.7969 18.9531Z" fill="white"/>
                </svg>
            </div>
            <div className="openedimage-img">
                <img alt="podgląd zdjęcia" src={image}/>
            </div>
        </div>
    )
}

function Slider({images, openImage}){
    const [ style, setStyle ] = useState({})
    const [ slide, setSlide ] = useState(0)
    const [ maxitems, setMaxitems ] = useState(4)
    useEffect(() => {
        let width = window.innerWidth
        if(width < 1370){
            setMaxitems(3)
        }
        if(width < 1110){
            setMaxitems(2)
        }
        if(width < 960){
            setMaxitems(3)
        }
        if(width < 820){
            setMaxitems(3)
        }
        if(width < 600){
            setMaxitems(1)
        }
        let newStyle = {
            marginLeft: -(slide * 171) + "px"
        }
        setStyle({...newStyle})
    }, [slide])
    const changeSlide = (back) => {
        if(back){
            if(slide === 0) return
            let newSlide = slide - 1
            setSlide(newSlide)
        }
        else{
            let len = images.length - 1 //-1 bo thumbnail trzeba usunac
            if(slide >= len-maxitems || len < maxitems-1) return
            let newSlide = slide + 1
            setSlide(newSlide)
        }
    }
    return(
        <div className="details-slider">
            <span className={`details-slider-button ${slide === 0 ? "notclickable" : ""}`} onClick={() => changeSlide(true)}>
                <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 1L1 3L3 5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <div className="details-scroll-images-overflow">
                <div className="details-scroll-images" style={style}>
                    {images.filter((img) => img.isThumbnail === false).map((img) => <div onClick={() => openImage(img)} className="details-scroll-image"><img alt="podgląd" src={img.image}/></div>)}
                </div>
            </div>
            <span className={`details-slider-button ${slide === images.length-1-maxitems || images.length-1 < maxitems-1 ? "notclickable" : ""}`} onClick={() => changeSlide(false)}>
                <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5L3 3L1 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </div>
    )
}

function DetailsArea({area, rooms, description, title, key_1, value_1, project_cost, project_id, orderBlocked}){
    return(
        <div className="details-area">
            <div className="details-area-header">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            <div className='details-area-summary'>
                <span>Powierzchnia domu</span>
                <span>{Number(area)}m²</span>
            </div>
            <div className="details-area-area-more">
                {rooms.map(room => (
                    <div className="details-area-area-more-row">
                        <span>{room.label}</span>
                        <span>{Number(room.size)}m²</span>
                    </div>
                ))}
                <hr/>
                <div className="details-area-area-more-row">
                    <span>Łącznie</span>
                    <span>{Number(area)}m²</span>
                </div>
            </div>
            <div className='details-area-summary'>
                <span>{key_1}</span>
                <span>{value_1}</span>
            </div>
            {orderBlocked === false ? 
                <Link reloadDocument to={`/koszyk/${project_id}`}>
                    <button>Zamów projekt za jedyne <span className="price">{project_cost} PLN</span></button>
                </Link>
                :
                <button className='catalog-project-button-notbuyable'>Brak możliwości zamówienia</button>
            }
        </div>
    )
}

function Description({description, facades, technology}){
    const [chosen, choose] = useState(2)
    const [choosedfacade, setChoosedfacade] = useState(0)

    function renderDescription(text) {
        // Replace occurrences of \n with actual newline characters
        return text.replace(/\\n/g, '\n');
    }
    
    return(
        <div className="details-description">
            <div className="details-description-header">
                <span className={`details-description-header-option ${chosen === 1 ? 'active' : ""}`} onClick={() => choose(1)}>Powierzchnia</span>
                <span className={`details-description-header-option ${chosen === 2 ? 'active' : ""}`} onClick={() => choose(2)}>Elewacje</span>
                <span className={`details-description-header-option ${chosen === 3 ? 'active' : ""}`} onClick={() => choose(3)}>Technologia</span>
            </div>
            {chosen === 1 ? 
                <div className="details-description-description">
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {renderDescription(description)}
                    </p>
                </div>
                :
                ""
            }
            {chosen === 2 ? 
                <div className="details-description-description">
                    <div className="details-facades">
                        <div className="details-facades-buttons">
                            <span className="details-facades-button" onClick={() => setChoosedfacade(0)}>N</span>
                            <span className="details-facades-button" onClick={() => setChoosedfacade(1)}>E</span>
                            <span className="details-facades-button" onClick={() => setChoosedfacade(2)}>S</span>
                            <span className="details-facades-button" onClick={() => setChoosedfacade(3)}>W</span>
                        </div>
                        <div className="details-facades-image">
                            <img alt="elewacja" src={facades[choosedfacade].image}></img>
                        </div>
                    </div>
                </div>
                :
                ""
            }
            {chosen === 3 ? 
                <div className="details-description-description">
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {renderDescription(technology)}
                    </p>
                </div>
                :
                ""
            }
        </div>
    )
}

function DetailsQuestion({question}){
    const [ opened, setOpened ] = useState(false)
    return(
        <div className="details-question">
            <div className="details-question-button" onClick={() => setOpened(!opened)}>
                {question.question}
                {opened ? 
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="7.5" stroke="black"/>
                        <path d="M6 9L8 7L10 9" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    :
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="7.5" stroke="black"/>
                        <path d="M6 7L8 9L10 7" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                }
            </div>
            {opened ? <div className="details-question-description">{question.answer}</div> : ""}
        </div>
    )
}

function DetailsButtons({questions}){
    return(
        <div className='details-buttons'>
            {questions.map((question) => <DetailsQuestion question={question}></DetailsQuestion>)}
            <Link reloadDocument to="/kontakt/">
                <button className="details-button">
                    Zadaj pytanie do projektu
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 7L13.03 12.7C12.7213 12.8934 12.3643 12.996 12 12.996C11.6357 12.996 11.2787 12.8934 10.97 12.7L2 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </Link>
            <a href="tel:+48600238671">
                <button className="details-button light">
                    Masz pytania? Zadzwoń +48 600 238 671
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </a>
        </div>
    )
}

function Plan({secondary_images, rooms, maxlevel, openPlan}){
    const [ level, setLevel ] = useState(0)
    const [ mirrorenabled, setMirrorenabled ] = useState(false)
    const [ roomSize, setRoomSize ] = useState(0)
    const mirrored = secondary_images.filter((img) => img.isMirror === true)
    const notmirrored = secondary_images.filter((img) => img.isMirror === false)
    function sum(items, prop){
        return items.reduce(function(a, b){
            return Number(a) + Number(b[prop]);
        }, 0);
    }
    useEffect(() => {
        let array = rooms.filter((room) => room.room_type === level)
        setRoomSize(sum(array, 'size'))
    }, [level])
    const changeLevel = (lower) => {
        if(lower){
            if(level === 0) return
            setLevel(level - 1)
        }
        else{
            if(level === maxlevel) return
            setLevel(level + 1)
        }
    }
    return(
        <div className="details-plan">
            <div className="details-plan-image">
                {mirrorenabled ? 
                    <img alt="zdjęcie" onClick={() => openPlan(mirrored[level].image)} src={mirrored[level].image}/>
                    :
                    <img alt="zdjęcie" onClick={() => openPlan(notmirrored[level].image)} src={notmirrored[level].image}/>
                }
            </div>
            <div className="details-plan-info">
                <div className="details-plan-info-header"><h3>Rzut {level === 0 ? "parteru" : `${level} piętra`}</h3></div>
                <div className="details-plan-info-rooms">
                    {rooms.filter((room) => room.room_type === level).map((room) => (
                        <div className="details-plan-info-room">
                            <span>{room.label}</span>
                            <span>{Number(room.size)}m²</span>
                        </div>
                    ))}
                    <hr></hr>
                    <div className="details-plan-info-room">
                        <span>Łącznie</span>
                        <span>{Number(roomSize).toFixed(2)}m²</span>
                    </div>
                </div>
                <div className="details-plan-info-mirror" onClick={() => setMirrorenabled(!mirrorenabled)}>
                    <span className={`details-plan-info-mirror-btn ${mirrorenabled ? 'active' : ""}`}></span>
                    <span className="details-plan-info-mirror-title">Lustrzane odbicie</span>
                </div>
                <div className="details-plan-buttons">
                    <span className='details-plan-button' onClick={() => changeLevel(true)}>
                        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 2L3 10L11 18" stroke="white" stroke-width="3"/>
                        </svg>
                    </span>
                    <span className='details-plan-button' onClick={() => changeLevel(false)}>
                        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L10 10L2 18" stroke="white" stroke-width="3"/>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}

export function Details(){
    const { id } = useParams()

    const [project, setProject] = useState()
    const [maxlevel, setMaxlevel] = useState(0)
    const [openedimage, setopenedimage] = useState("")
    const [openedplan, setopenedplan] = useState("")
    const [openedimageid, setopenedimageid] = useState(0)
    const [thumbnail, setThumbnail] = useState({})
    const [images, setImages] = useState([])
    const openImage = (img) => {
        console.log(img)
        setopenedimage(img.image)
        let id = images.indexOf(img)
        console.log(id)
        setopenedimageid(id)
    }
    const closeImage = () => {
        setopenedimage("")
    }
    const closePlan = () => {
        setopenedplan("")
    }
    const changeImage = (back) => {
        if(back === true){
            let id
            if(openedimageid === 0){
                id = images.length-1
            }
            else{
                id = openedimageid - 1
            }
            setopenedimage(images[id].image)
            setopenedimageid(id)
        }
        else{
            let id
            if(openedimageid === images.length-1){
                id = 0
            }
            else{
                id = openedimageid + 1
            }
            setopenedimage(images[id].image)
            setopenedimageid(id)
        }
    }

    const [ loaded, setLoaded ] = useState(false)
    useEffect(() => {
        axios.get(`${url}/natalia/projects/${id}/`)
        .then((res) => {
            setLoaded(true)
            setProject(res.data)
            setThumbnail(res.data.images.filter((img) => img.isThumbnail === true)[0])
            setImages(res.data.images)
            let maxlvl = Math.max(...res.data.rooms.map(o => o.room_type))
            setMaxlevel(maxlvl)
            document.title = `Projekty Gebala - ${res.data.name}`;
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, [id]);
    return(
        <div className="details">
            {openedimage ? <OpenedImage changeImage={changeImage} closefunc={closeImage} image={openedimage}></OpenedImage> : ""}
            {openedplan ? <OpenedPlan closefunc={closePlan} image={openedplan}></OpenedPlan> : ""}
            <div className='container'>
                <img alt="Grafika dom" className="bg-plan" src={`${cloudfrontUrl}/bg-plan.png`}/>
                <Navbar></Navbar>
                {
                    loaded ? 
                    <>                    
                        <div className="details-header">
                            <Link to="/katalog" className="details-header-back">
                                <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 2L3 12L13 22" stroke="white" stroke-width="4"/>
                                </svg>
                            </Link>
                            <h1>{project.name}</h1>
                        </div>
                        <div className="details-container">
                            <div className="details-summary">
                                <div className="details-thumbnail">
                                    <img alt={project.name} onClick={() => openImage(thumbnail)} src={thumbnail.image}/>
                                </div>
                                <Slider openImage={openImage} images={images}></Slider>
                            </div>
                            <DetailsArea project_id={id} value_1={project.value_1} key_1={project.key_1} project_cost={project.project_price} area={project.house_area} rooms={project.rooms} title={project.name} description={project.small_description} orderBlocked={project.orderBlocked}></DetailsArea>
                            <Description facades={project.facade_images} description={project.big_description} technology={project.technology_description}></Description>
                            <DetailsButtons questions={project.faqs}></DetailsButtons>
                            <Plan openPlan={setopenedplan} maxlevel={maxlevel} rooms={project.rooms} secondary_images={project.secondary_images}></Plan>
                        </div>
                    </>
                    :
                    <Loader></Loader>
                }
            </div>  
            <Footer></Footer>
        </div>
    )
}